<template>
	<div class="form-group">

	<div class="form-group">
		<h4>{{ $t('cogView.cogCalculator') }} <help-button helpName="cog" :invertedColor="true" /> </h4>
    <div class="row">
      <div class="col-12">
        <DataTable
          :value="cogValues || []"
          responsiveLayout="scroll"
          currentPageReportTemplate="{totalRecords}"
          stateStorage="local"
					stateKey="dt-state-demo-local"
        >
          <template #header>
            <div class="row">
              <div class="col-12 col-md-6 offset-md-3">
                <label class="font-weight-bold">{{ $t('cogView.selectPlane') }}: </label>
                <select class=" form-control" v-model="selectedPlaneId" v-on:change="onSelectedPlaneChange()" id="selectedPlane">
									<option :value="undefined">
										{{ $t('auto-complete.please-choose') }}
									</option>
                  <option v-for="plane in planes" v-bind:key="plane.id" :value="plane.id">
										{{plane.registrationNumber}}
									</option>
								</select>
              </div>
            </div>
          </template>
        <Column :header="$t('cogView.name')">
            <template #body="slotProps">
              <TextInput
								v-if="slotProps.data.mandatory"
								:value="$t(slotProps.data.name)"
                :msgs="msgs"
                class="form-control"
                :readonly="true"
                style="min-width: 8rem"
              />
							<TextInput
								v-else
								v-model="slotProps.data.name"
								:msgs="msgs"
								class="form-control"
								style="min-width: 8rem"
								:required="true"
								@blur="saveCogValues()"
							/>
            </template>
        </Column>
        <Column :header="$t('cogView.weight')">
          <template #body="slotProps">
          <div class="d-flex">
              <div class="d-block w-100" style="flex: 1">
                <InputNumber
                  v-model="slotProps.data.weight"
                  mode="decimal"
                  :minFractionDigits="0"
                  :maxFractionDigits="findUnit(slotProps.data.unit).decimals"
                  :useGrouping="false"
                  class="w-100"
                  v-on:blur="saveCogValues()"
                />
            </div>
            <div class="d-block" v-if="!slotProps.data.fuelType">
              <select class=" form-control h-100"
                style="min-width: 5rem;"
                :value="slotProps.data.unit"
                v-on:change="(e) => onUnitChange(slotProps.data, e.target.value)"
                id="unit">
                <option value="kg">{{$t('unit.kg')}}</option>
                <option value="lbs">{{$t('unit.lbs')}}</option>
              </select>
            </div>
            <div class="d-block" v-if="slotProps.data.fuelType">
              <select class=" form-control h-100"
                v-model="slotProps.data.fuelType"
                style="min-width: 6rem;"
                v-on:change="saveCogValues()"
                id="fuelType">
                <option value="petrol">{{$t('unit.petrol')}}</option>
                <option value="kerosine">{{$t('unit.kerosine')}}</option>
              </select>
            </div>
            <div class="d-block" v-if="slotProps.data.fuelType">
              <select class=" form-control h-100"
                :value="slotProps.data.unit"
                style="min-width: 5rem;"
                v-on:change="(e) => onUnitChange(slotProps.data, e.target.value)"
                id="unit">
                <option value="l">{{$t('unit.liter')}}</option>
                <option value="gal">{{$t('unit.gallon')}}</option>
              </select>
            </div>
          </div>
          </template>
        </Column>
        <Column :header="$t('cogView.centerOfGravity')">
          <template #body="slotProps" >
            <div class="d-flex">
              <div class="d-block w-100" style="flex: 1">
                  <InputNumber
                    v-model="slotProps.data.cog"
                    mode="decimal"
                    inputStyle="max-width: 8rem"
                    :minFractionDigits="0"
                    :maxFractionDigits=" findUnit(slotProps.data.cogUnit).decimals"
                    localeMatcher="lookup"
                    :useGrouping="false"
                    v-on:blur="saveCogValues()"
                  />
              </div>
              <div class="d-block" >
                <select class=" form-control h-100"
                  style="min-width: 5rem;"
                  :value="slotProps.data.cogUnit || 'in'"
                  v-on:change="(e) => onCogUnitChange(slotProps.data, e.target.value)"
                  id="cogUnit">
                  <option value="in">{{$t('unit.inch')}}</option>
                  <option value="m">{{$t('unit.meter')}}</option>
                  <option value="cm">{{$t('unit.centimeter')}}</option>
                </select>
              </div>
            </div>
          </template>
        </Column>
        <Column :header="$t('cogView.torque')">
            <template #body="slotProps">
              <InputNumber
                  :value="calculateTorque(slotProps.data)"
                  mode="decimal"
                  inputStyle="max-width: 8rem;font-style: italic"
                  :minFractionDigits="0"
                  :maxFractionDigits="2"
                  localeMatcher="lookup"
                  :useGrouping="false"
                  suffix=" [kgin]"
                  :readonly="true"
                  inputClass="border-0"
              />
            </template>
        </Column>
        <Column>
            <template #body="slotProps">
              <button :disabled="slotProps.data.mandatory" @click="removeRow(slotProps.data)" class="btn btn-danger">
                <font-awesome-icon style="margin: 0" icon="trash"/>
              </button>
            </template>
        </Column>
        <template #footer>
            <div class="d-flex justify-content-center mb-2">
                <button @click="addCogValue()" class="btn btn-primary">
                  <font-awesome-icon icon="plus"/>
                  {{ $t('cogView.add') }}
                </button>
            </div>
            <h3 class="mt-5 text-center font-weight-bold" >{{ $t('cogView.result') }}:</h3>
            <div class="row mt-3">
              <div class="col-12 col-md-6">
                <label >{{ $t('cogView.takeOffWeight') }}: </label>
                <div class="d-flex">
                  <div style="flex: 1">
                    <InputNumber
                      :readonly="true"
                      :value=" (findUnit(totalWeightUnit || 'kg').fromTrueValue || ((n) => n))(calculateTotalWeight())"
                      mode="decimal"
                      class="w-100"
                      inputClass="w-100 bg-light font-weight-bold border-0 px-0"
                      inputStyle="text-decoration: underline;font-size: 1.5rem;"
                      :minFractionDigits="0"
                      :maxFractionDigits="findUnit(totalWeightUnit).decimals"
                      :suffix="' ' + (findUnit(totalWeightUnit).suffix || '')"
                    />
                  </div>
                  <div class="d-block">
                    <select class=" form-control h-100"
                      style="min-width: 5rem;"
                      v-model="totalWeightUnit"
                      id="unit">
                      <option value="kg">{{$t('unit.kg')}}</option>
                      <option value="lbs">{{$t('unit.lbs')}}</option>
                    </select>
                  </div>
                </div>
                <div class="mt-2 font-weight-normal">
                    {{$t('cogView.fuelTrueWeight')}} {{(findUnit(totalWeightUnit).fromTrueValue || ((n) => n))(calculateFuelWeight()).toFixed(2)}}
                    {{findUnit(totalWeightUnit).suffix || totalWeightUnit}}
                </div>
              </div>
              <div class="col-12 mt-3 mt-md-0 col-md-6">
                <label class="">{{$t('cogView.centerOfGravity')}}: </label>
                <div class="d-flex">
                  <div style="flex: 1">
                    <InputNumber
                      :readonly="true"
                      :value="calculateTotalCog()"
                      mode="decimal"
                      class="w-100"
                      inputStyle="text-decoration: underline;font-size: 1.5rem;"
                      inputClass="w-100 bg-light border-0 font-weight-bold px-0"
                      :minFractionDigits="0"
                      :maxFractionDigits="findUnit(totalCogUnit).decimals"
                      :suffix="' ' + (findUnit(totalCogUnit).suffix || '')"
                    />
                  </div>
                  <div class="d-block">
                    <select class=" form-control h-100"
                      style="min-width: 5rem;"
                      v-model="totalCogUnit"
                      id="unit">
                      <option value="in">{{$t('unit.inch')}}</option>
                      <option value="m">{{$t('unit.meter')}}</option>
                      <option value="cm">{{$t('unit.centimeter')}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

					<div class="row mt-3">
						<div class="col-12 col-md-6">
							<label >{{ $t('cogView.landingWeight') }}: </label>
							<div class="d-flex">
								<div style="flex: 1">
									<InputNumber
										:readonly="true"
										:value=" (findUnit(totalWeightUnit || 'kg').fromTrueValue || ((n) => n))(calculateLandingTotalWeight())"
										mode="decimal"
										class="w-100"
										inputClass="w-100 bg-light font-weight-bold border-0 px-0"
										inputStyle="text-decoration: underline;font-size: 1.5rem;"
										:minFractionDigits="0"
										:maxFractionDigits="findUnit(totalWeightUnit).decimals"
										:suffix="' ' + (findUnit(totalWeightUnit).suffix || '')"
									/>
								</div>
								<div class="d-block">
									<select class=" form-control h-100"
													style="min-width: 5rem;"
													v-model="totalWeightUnit"
													id="unit">
										<option value="kg">{{$t('unit.kg')}}</option>
										<option value="lbs">{{$t('unit.lbs')}}</option>
									</select>
								</div>
							</div>
							<div class="mt-2 font-weight-normal">
								{{$t('cogView.fuelTrueWeight')}} {{calculateLandingFuelWeight() | convert('kg', totalWeightUnit)}}
								{{findUnit(totalWeightUnit).suffix || totalWeightUnit}}
							</div>
						</div>
						<div class="col-12 mt-3 mt-md-0 col-md-6">
							<label class="">{{$t('cogView.centerOfGravity')}}: </label>
							<div class="d-flex">
								<div style="flex: 1">
									<InputNumber
										:readonly="true"
										:value="calculateLandingCog()"
										mode="decimal"
										class="w-100"
										inputStyle="text-decoration: underline;font-size: 1.5rem;"
										inputClass="w-100 bg-light border-0 font-weight-bold px-0"
										:minFractionDigits="0"
										:maxFractionDigits="findUnit(totalCogUnit).decimals"
										:suffix="' ' + (findUnit(totalCogUnit).suffix || '')"
									/>
								</div>
								<div class="d-block">
									<select class=" form-control h-100"
													style="min-width: 5rem;"
													v-model="totalCogUnit"
													id="unit">
										<option value="in">{{$t('unit.inch')}}</option>
										<option value="m">{{$t('unit.meter')}}</option>
										<option value="cm">{{$t('unit.centimeter')}}</option>
									</select>
								</div>
							</div>
						</div>
					</div>

            <SaveToBriefingButton class="mt-3" @click="addToBriefing()" :added="selectedPlane.addedToBriefing" type="cog" />
        </template>
        </DataTable>
      </div>
    </div>
		<CustomPlaneFormCogData ref="cogForm"
														:msgs="msgs"
														:planeToSave="selectedPlane"
														:showMode="true"
														v-if="selectedPlane.id && selectedPlane.gravityYUnit"
		/>
	</div>
	</div>
</template>

<script>
import briefingmanager from "@/plugins/briefingmanager";
import SaveToBriefingButton from "../SaveToBriefingButton.vue";
import CustomPlaneFormCogData from "@/components/plane/customPlaneForm/CustomPlaneFormCogData.vue";
import HelpButton from "../help/HelpButton.vue";
import units from "@/plugins/units";

export default {
	name: "CogCalculatorForm",
	props: {
		msgs: Object,
	},
	components: {  SaveToBriefingButton, CustomPlaneFormCogData, HelpButton},
	data() {
		return {
			/** @type {Plane[]} */
      planes: [],
			selectedPlaneId: null,
			/** @type {Plane} */
			selectedPlane: {},
			/** @type {CogValue[]} */
			cogValues: [],
      totalWeightUnit: "kg",
      totalCogUnit: 'in',
			decimals: {
				kg: 0,
				lbs: 0,
				l: 0,
				gal: 1,
				in: 1,
				m: 3,
				cm: 1,
			},
		}
	},
	methods: {
		/**
		 * @param {CogValue} cogValue
		 * @param {string} newUnit
		 * */
    onUnitChange(cogValue, newUnit){
      if(cogValue == null) return;
      if(newUnit == null) return;
			this.cogValues = this.cogValues.map(v => {
        if(v.id && cogValue.id ? v.id === cogValue.id : v.index === cogValue.index) {
          const oldUnit = this.findUnit(cogValue.unit)
          const unit = this.findUnit(newUnit)
          v.weight = unit.fromTrueValue(oldUnit.trueValue(cogValue.weight));
          v.unit = unit.key;
        }
        return v;
      })
      this.saveCogValues();
    },

		/**
		 * @param {CogValue} cogValue
		 * @param {string} newUnit
		 * */
    onCogUnitChange(cogValue, newUnit){
      if(cogValue == null) return;
      if(newUnit == null) return;
      this.cogValues = this.cogValues.map(v => {
        if(v.id && cogValue.id ? v.id === cogValue.id : v.index === cogValue.index) {
          const oldUnit = this.findUnit(cogValue.cogUnit || 'in');
          const unit = this.findUnit(newUnit);
          v.cog = unit.fromTrueValue(oldUnit.trueValue(cogValue.cog));
          v.cogUnit = unit.key;
        }
        return v;
      })
      this.saveCogValues();
    },

    addCogValue(){
      if(!this.selectedPlane)  return;

      this.cogValues = [...this.cogValues, {
        name: '',
        index: this.cogValues.length,
        mandatory: false,
        weight: 0,
        unit: 'kg',
        cog: 0,
        cogUnit: 'in',
      }];

      this.saveCogValues();
    },

		/** @param {CogValue} cogValue */
    removeRow(cogValue) {
      if(cogValue.mandatory) return ;
      this.cogValues = this.cogValues.filter(v => {
        return v.id && cogValue.id ? v.id !== cogValue.id : v.index !== cogValue.index;
      });
      this.saveCogValues();
    },

    calculateFuelWeight() {
      if(!this.cogValues) return 0;
      const cogValue = this.cogValues.find(c => c.fuelType);
      if(cogValue == null) return 0;
			console.log(JSON.parse(JSON.stringify(cogValue)));
      return this.calculateTrueWeight(cogValue);
    },

		calculateLandingFuelWeight() {
			if(!this.selectedPlane.defaultConsumption) return 0;
			const cogValue = this.cogValues.find(c => c.fuelType);
			if(cogValue == null) return 0;
			const stat = {...cogValue};
			stat.weight = this.selectedPlane.defaultConsumption*0.75;
			return this.calculateTrueWeight(stat);
		},

		/** @param {CogValue} cogValue */
    calculateTrueWeight(cogValue) {
      let weight = typeof cogValue.weight == 'number' ? cogValue.weight :  Number(cogValue.weight);
      const unit = cogValue.unit;
      const fuelType = cogValue.fuelType;

			//FuelType
			if(fuelType === "petrol") weight *= 0.72;
			if(fuelType === "kerosine") weight *= 0.8;

      //Unit
			return units.convert(weight, unit, this.totalWeightUnit);
    },

		/** @param {CogValue} cogValue */
    calculateTrueCog(cogValue) {
      let cog = typeof cogValue.cog == 'number' ? cogValue.cog :  Number(cogValue.cog);
      const unit = this.findUnit(cogValue.cogUnit || 'in');
      return unit.trueValue(cog);
    },

		/** @param {CogValue} cogValue */
    calculateTorque(cogValue) {
      let weight = this.calculateTrueWeight(cogValue);
      let cog = this.calculateTrueCog(cogValue)

      return weight * cog;
    },

    calculateTotalWeight(){
      if(!this.cogValues) return 0;
			return this.cogValues.reduce((before, v) => {
				return this.calculateTrueWeight(v) + before;
			}, 0);
    },

		calculateLandingTotalWeight(){
			if(!this.cogValues) return 0;
			return this.cogValues.reduce((before, v) => {
				const stat = { ...v };
				if (stat.fuelType !== undefined && this.selectedPlane.defaultConsumption) {
					stat.weight = this.selectedPlane.defaultConsumption * 0.75;
				}
				return this.calculateTrueWeight(stat) + before;
			}, 0);
		},

    calculateTotalCog(){
      if(!this.cogValues) return 0;
      const total = this.cogValues.reduce((before,v) => {
        return this.calculateTorque(v) + before;
      }, 0)
			const totalWeight = this.calculateTotalWeight();
			const n = total / totalWeight;
      const unit = this.findUnit(this.totalCogUnit);
			this.showOnDiagram(n,  totalWeight, this.calculateLandingCog(), this.calculateLandingTotalWeight());
      return unit.fromTrueValue(n) || 0;
    },

		calculateLandingCog(){
			if(!this.cogValues) return 0;
			const total = this.cogValues.reduce((before,v) => {
				const stat = {... v};
				if(stat.fuelType !== undefined && this.selectedPlane.defaultConsumption){
					stat.weight = this.selectedPlane.defaultConsumption * 0.75;
				}
				return this.calculateTorque(stat) + before;
			}, 0)
			const totalWeight = this.calculateLandingTotalWeight();
			const n = total / totalWeight;
			const unit = this.findUnit(this.totalCogUnit);
			return unit.fromTrueValue(n) || 0;
		},

		showOnDiagram(n , totalWeight, m, landingTotalWeight) {
			if (this.selectedPlane.gravityYUnit) {
				const diagramWeightUnit = this.findUnit(this.selectedPlane.gravityYUnit);
				const diagramCogUnit = this.findUnit(this.selectedPlane.gravityXUnit);
				if (this.$refs.cogForm) {
					this.$refs.cogForm.showCog(
						diagramWeightUnit.fromTrueValue(totalWeight),
						diagramCogUnit.fromTrueValue(n),
						diagramWeightUnit.fromTrueValue(landingTotalWeight),
						m,
					);
				}
			}
		},

    async onSelectedPlaneChange(){
			if(!this.selectedPlaneId) return;
			this.selectedPlane = this.planes.find(p => p.id === this.selectedPlaneId);
			if(!this.selectedPlane || !this.selectedPlane.id) {
				this.resetSelectedPlane();
				return;
			}
			this.cogValues = await this.$rest.loadCogValues(this.selectedPlane.id);
			if(this.cogValues.length === 0)  {
				this.resetSelectedPlane()
			}
    },

    resetSelectedPlane() {
      this.cogValues = [
				{
					index: 0,
					name: 'cogView.emptyPlane',
					weight: 0,
					mandatory: true,
					unit: 'kg',
					cog: 0,
					cogUnit: 'in',
				},
				{
					index: 1,
					name: 'cogView.fuel',
					weight: 0,
					mandatory: true,
					unit: 'l',
					cog: 0,
					fuelType: 'petrol',
					cogUnit: 'in',
				},
				{
					index: 2,
					name: 'cogView.pilotWeight',
					weight: 0,
					mandatory: true,
					unit: 'kg',
					cog: 0,
					cogUnit: 'in',
				}
			];
    },

    saveCogValues() {
      this.selectedPlane = {...this.selectedPlane, addedToBriefing: false}
      if(!this.selectedPlane.id) return;
			this.$rest.saveCogValues(this.selectedPlane.id, this.cogValues);
		},

		/**
		 * @param {AllUnitType} key
		 * @returns {Unit}
		 * */
    findUnit(key) {
      return {
				...(units.getUnit(key) || {}),
				decimals: this.decimals[key] || 0,
			};
    },
    addToBriefing() {
      this.selectedPlane= {...this.selectedPlane, addedToBriefing: true}
      briefingmanager.addSubGroup("cog", {
                key: `${this.selectedPlane.id || ''}`,
                title: `<h2 style="margin: 0; text-align: center" >${this.selectedPlane.registrationNumber || ""}</h2>`,
								localTitle: this.selectedPlane.registrationNumber || "",
                tables: [
                    {
                        key: "fullcog",
                        rows: [
                            [
                                {
																		style: "flex: 1; text-align: center; font-weight: bold;",
                                    value: "#{cogView.name}",
                                },
                                {
																		style: "flex: 1; text-align: center; font-weight: bold;",
                                    value: "#{cogView.weight}",
                                },
                                {
																		style: "flex: 1; text-align: center; font-weight: bold;",
                                    value: "#{cogView.centerOfGravity}",
                                },
                                {
																		style: "flex: 1; text-align: center; font-weight: bold;",
                                    value: "#{cogView.torque}",
                                },
                            ],
                            ...this.cogValues.map(c => {
                              return [
                                {
																		style: "flex: 1; text-align: center; font-weight: bold;",
                                    value: `${c.mandatory ? `#{${c.name}}` : c.name}`,
                                },
                                {
																		style: "flex: 1; text-align: center;",
                                    value: `${c.weight.toFixed(this.findUnit(c.unit || 'kg').decimals)} ${this.findUnit(c.unit).suffix || ''} ${!c.fuelType ? '' : `#{unit.${c.fuelType}}`}`,
                                },
                                {
                                    style: "flex: 1; text-align: center;",
                                    value: `${c.cog.toFixed(this.findUnit(c.cogUnit || 'in').decimals)} ${this.findUnit(c.cogUnit || 'in').suffix || ''}`,
                                },
                                {
                                    style: "flex: 1; text-align: center;",
                                    value: `${this.calculateTorque(c).toFixed(2)} [kgin]`,
                                },
                              ]
                            }),
                            [
                                {
                                    style: "border-top: solid 3px black; flex: 1; text-align: center; font-weight: bold;",
                                    value: "#{cogView.takeOffWeight}",
                                },
                                {
																		style: "border-top: solid 3px black; flex: 1; text-align: center; font-weight: bold;",
                                    value: "#{cogView.centerOfGravity}",
                                },
                            ],
                            [
                                {
                                    style: "flex: 1; text-align: center; font-weight: bold;",
                                    value: `${ (this.findUnit(this.totalWeightUnit || 'kg').fromTrueValue || ((n) => n))(this.calculateTotalWeight()).toFixed(this.findUnit(this.totalWeightUnit || 'kg').decimals)} ${ this.findUnit(this.totalWeightUnit || 'kg').suffix || ''}`,
                                },
                                {
                                    style: "flex: 1; text-align: center; font-weight: bold;",
                                    value: `${this.calculateTotalCog().toFixed(this.findUnit(this.totalCogUnit || 'in').decimals)} ${this.findUnit(this.totalCogUnit || 'in').suffix || ''}`,
                                },
                            ]
                        ]
                    }
                ]
            });
    }
	},
	async beforeMount() {
		this.planes = (await this.$rest.loadMyUserPlanes() || []).map(up => up.plane);
    this.totalWeightUnit = window.consentedStorage.getItem('totalWeightUnit') || 'kg';
    this.totalCogUnit = window.consentedStorage.getItem('totalCogUnit') || 'in';
		this.resetSelectedPlane();
	},
  watch: {
    totalWeightUnit(){
      window.consentedStorage.setItem('totalWeightUnit', this.totalWeightUnit)
    },
    totalCogUnit(){
      window.consentedStorage.setItem('totalCogUnit', this.totalCogUnit)
    }
  }
}
</script>

<style scoped>
</style>
